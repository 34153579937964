<template>
  <!-- 新通证申购订单管理 -->
  <div>
    <!-- 顶部搜索框 -->
    <div class="form">
      <!-- 输入手机号码查询 -->
      <div>
        <span>按手机号查</span>
        <div>
          <el-input
            v-model="form.phone"
            placeholder="请输入手机号"
            prefix-icon="el-icon-mobile-phone"
            style="width: 220px"
          ></el-input>
        </div>
      </div>
      <!-- 请选择对应数字通证 -->
      <div>
        <span>数字通证</span>
        <div>
          <el-select
            v-model="value"
            placeholder="请选择对应数字通证"
            style="width: 220px"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- 请选择对应数字通证 -->
      <div>
        <span>是否中签</span>
        <div>
          <el-select
            v-model="value"
            placeholder="请选择是否中签"
            style="width: 220px"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- 请选择对应数字通证 -->
      <div>
        <span>扣款情况</span>
        <div>
          <el-select
            v-model="value"
            placeholder="请选择是否扣款"
            style="width: 220px"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <el-button
        type="primary"
        icon="el-icon-search"
        style="height: 40px; margin-top: 40px"
        @click="doFilter"
        >搜索</el-button
      >
      <el-button
        type="primary"
        icon="el-icon-refresh"
        style="height: 40px; margin-top: 40px"
        @click="reset"
        >重置</el-button
      >
    </div>

    <!-- 中间表格区 -->
    <div>
      <el-table border>
        <el-table-column label="订单号" align="center"></el-table-column>
        <el-table-column label="申购时间" align="center"></el-table-column>
        <el-table-column label="手机号" align="center"></el-table-column>
        <el-table-column label="姓名" align="center"></el-table-column>
        <el-table-column label="数字通证" align="center"></el-table-column>
        <el-table-column label="申购价格" align="center"></el-table-column>
        <el-table-column label="申购数量" align="center"></el-table-column>
        <el-table-column label="是否中签" align="center"></el-table-column>
        <el-table-column label="扣款情况" align="center"></el-table-column>
        <el-table-column label="扣款时间" align="center"></el-table-column>
        <el-table-column label="扣款金额" align="center"></el-table-column>
        <el-table-column label="实际获得数量" align="center"></el-table-column>
      </el-table>
    </div>
    <!-- 底部分页 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[10, 15, 20, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        time1: "",
        time2: "",
        phone: "",
        bank: "",
        cardNumber: "",
        maxAmount: "",
        minAmount: "",
        userName: "",
      },
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      total: 23,
      currentPage4: 1,
    };
  },
  methods: {
    //   搜索
    doFilter() {},
    // 重置
    reset() {
      this.form.phone = "";
      this.value = "";
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  margin-bottom: 30px;
  justify-content: space-around;
  div {
    margin-right: 3px;
    span {
      //   font-weight: bold;
      font-size: 14px;
      display: inline-block;
      margin-bottom: 20px;
    }
  }
}
.pagination {
  padding: 60px 0 0 100px;
}
</style>
